.Image {
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity $timing-fast;
  }

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.035);
  }

  &__loader--overlay {
    background-color: rgba(map.get($colors, 'Neutral700'), 0.5);
  }

  &__hover {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(map.get($colors, 'Neutral700'), 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;

    transition: opacity $timing-fast, visibility $timing-fast;
  }

  &__delete {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: map.get($colors, 'Neutral100');
    padding: 0.8rem;
    border-radius: 50%;
    opacity: 0.8;
    transition: opacity $timing-fast;
    cursor: pointer;

    &-icon {
      font-size: 1.8rem;
    }

    &:hover {
      opacity: 1;
    }
  }

  &:hover,
  &:focus-visible {
    .Image__hover {
      opacity: 1;
      visibility: visible;
      pointer-events: inherit;
    }
  }

  &--loaded img {
    opacity: 1;
  }

  // Image ratios
  &--standard {
    aspect-ratio: 4/3;
  }

  &--widescreen {
    aspect-ratio: 16/9;
  }
}

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  z-index: 99;

  transition: opacity $timing-fast;

  &__inner {
    position: relative;
    width: 100%;
    max-width: 60rem;
    max-height: calc(100vh - 5rem);
    padding: $modalPadding-y $modalPadding-x;
    padding-top: $modalPadding-y * 2;
    background-color: #ffffff;
    border-radius: 1rem;
    overflow: auto;
  }

  &--in {
    opacity: 1;
  }

  &--loading .Modal__inner:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    // background-color: rgba(255, 255, 255, 0.9);
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 99;
  }

  .Modal__inner .Spinner {
    z-index: 99;
    --spinner-background: #{map.get($colors, 'Neutral100')};
  }

  .Modal__close {
    position: absolute;
    top: $modalPadding-y;
    right: $modalPadding-x;

    font-size: 2.4rem;
    color: map.get($colors, 'Neutral600');

    transition: color $timing-fast;
    cursor: pointer;

    &:hover,
    &:focus {
      color: map.get($colors, 'Neutral800');
    }
  }

  .Modal__divider {
    margin: 2.4rem 0;
    border: 0;
    border-top: 0.1rem solid map.get($colors, 'Neutral200');
  }

  .Modal__verification {
    display: flex;
    align-items: center;
    justify-content: center;

    .TextField__control {
      max-width: 6.4rem;
      margin: 0 0.8rem;
    }

    .TextField__control {
      text-align: center;
    }
  }
}

.Modal--large .Modal__inner {
  max-width: 80rem;
}

.Modal--small .Modal__inner {
  max-width: 56rem;
}

.ModalLock {
  backdrop-filter: blur(3px);
  background-color: rgba(255, 255, 255, 0.15);
}

.ModalLock .Modal__inner {
  max-width: 54.5rem;
  padding-top: 8rem;
  padding-bottom: 6rem;
  box-shadow: 0px 2px 11px 4px rgba(191, 198, 205, 0.3);
}

.ModalLock .Modal__content {
  text-align: center;
}

.ModalLock__image {
  max-width: 10rem;
}

@import '../base/typography.scss';

.Editor__menubar {
  display: flex;
  gap: 0.5rem 1.6rem;
  margin-bottom: $base-grid-gap;
}

.Editor__menubar-item {
  font-size: 3rem;
  padding: 0.6rem;
  border-radius: 0.8rem;
  cursor: pointer;
}

.Editor__menubar-item--active.Editor__menubar-item--active {
  color: map-get($colors, 'Primary600');
  background-color: map-get($colors, 'Neutral200');
}

.Editor .is-editor-empty:before {
  position: absolute;
  top: -0.1rem;
  content: attr(data-placeholder);
  @extend .text-h3;
  color: map-get($colors, 'Neutral500');
}

.Editor--bordered {
  border: 0.1rem solid map.get($colors, 'Neutral300');
  border-radius: 0.8rem;
  padding: 1.4rem 1.6rem;
}

.Editor .ProseMirror {
  outline: none;
  min-height: 18rem;

  h1 {
    @extend .text-h1;
    margin-bottom: math.div($base-grid-gap, 2);
  }

  h2 {
    @extend .text-h2;
    margin-bottom: math.div($base-grid-gap, 2);
  }

  h3 {
    @extend .text-h3;
    margin-bottom: math.div($base-grid-gap, 3);
  }

  h4 {
    @extend .text-body-large;
  }

  p {
    @extend .text-body-medium;
    margin-bottom: math.div($base-grid-gap, 2);
  }

  a {
    color: map.get($colors, 'AccentOrange');
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ul,
  ol {
    margin: 0;
    margin-bottom: 1rem;
    padding-left: 2rem;

    p {
      margin: 0;
    }
  }
}

@use 'sass:math';

// Base
$base-grid-gap: 2.4rem;
$base-indent: math.div($base-grid-gap, 2);

// Breakpoints
$screen-sm: 576px;
$screen-sm-max: $screen-sm - 1;

$screen-md: 768px;
$screen-md-max: $screen-md - 1;

$screen-lg: 992px;
$screen-lg-max: $screen-lg - 1;

$screen-xl: 1200px;
$screen-xl-max: $screen-xl - 1;

$hamburger-breakpoint: $screen-lg;
$hamburger-breakpoint-max: $hamburger-breakpoint - 1;

$colors: (
  Neutral800: #171b20,
  Neutral750: #42474d,
  Neutral700: #5f6b7a,
  Neutral600: #8895a7,
  Neutral500: #b8c4ce,
  Neutral400: #dcdfe2,
  Neutral300: #e9e9e9,
  Neutral200: #f5f4f3,
  Neutral100: #ffffff,
  Blue800: #04384d,
  Blue700: #02475f,
  Blue600: #005776,
  Blue500: #75a4b5,
  Primary700: #036060,
  Primary600: #0a9797,
  Primary500: #0fafaf,
  Primary400: #3dcccc,
  Primary300: #85dede,
  Primary200: #c2ebeb,
  Primary100: #e8f4f4,
  AccentOrange: #fc6917,
  SystemRed: #d44a4b,
  SystemGreen: #4dab4c,
  SystemOrange: #e87810,
);

// Timing
$timing-fast: 150ms;
$timing-normal: 350ms;

// Utility
$utility-classes-iterations: 5;
$utility-classes-maps: (
  mt: margin-top,
  mr: margin-right,
  mb: margin-bottom,
  ml: margin-left,
  pt: padding-top,
  pr: padding-right,
  pb: padding-bottom,
  pl: padding-left,
);

// Content
$content-padding-y: 4rem;

// Card
$cardContent-padding-y: 2rem;
$cardContent-padding-x: 2.4rem;

// Modal
$modalPadding-x: 3.7rem;
$modalPadding-y: 3.7rem;

.Onboarding {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  flex-shrink: 0;

  @media (min-width: $screen-md) {
    flex-direction: row;
  }
}

.OnboardingSidebar {
  position: relative;
  width: 100%;
  max-height: 20rem;

  &:before {
    content: '';
    display: block;
    width: 100%;
    @include aspect-ratio(16, 9);
  }

  @media (min-width: $screen-md) {
    max-width: calc(#{100vw} / 3);
    max-height: none;

    &:before {
      content: none;
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.OnboardingMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 64.2rem;

  margin: auto;
}

.OnboardingForm {
  width: 100%;
  padding: 5rem 0;
}

.OnboardingDivider {
  display: flex;
  align-items: center;
  text-transform: uppercase;

  span {
    margin: 0 2rem;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 0.1rem;
    background: map.get($colors, 'Neutral300');
  }
}

.OnboardingMain .Content {
  padding: 0;
}

.Table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;

  tr {
    border-bottom: 0.1rem solid map.get($colors, 'Neutral300');

    &:last-child {
      border-bottom: 0;
    }
  }

  th,
  td {
    padding: 2.4rem 0;
    font-weight: 500;
    line-height: 2.4rem;
  }

  th {
    font-size: 1.5rem;
    color: map.get($colors, 'Neutral700');
  }

  td {
    font-size: 1.4rem;
    color: map.get($colors, 'Neutral600');
  }

  td:last-child {
    text-align: right;
  }
}

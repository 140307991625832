.CheckboxField__inner {
  display: flex;
  align-items: center;
  cursor: pointer;
}

// Variant checkbox
.CheckboxField--checkbox .CheckboxField__control {
  position: relative;

  flex-shrink: 0;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 0.4rem;
  border: 0.1rem solid map.get($colors, 'Primary500');

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: no-repeat center;
    background-image: url('../../images/icons/checkbox.svg');

    opacity: 0;
  }
}

.CheckboxField--checkbox .CheckboxField__input:checked + .CheckboxField__control {
  background-color: map.get($colors, 'Primary500');

  &:after {
    opacity: 1;
  }
}

.CheckboxField--checkbox .CheckboxField__label {
  margin-left: 1.4rem;

  color: map.get($colors, 'Neutral700');
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 500;

  a {
    color: map.get($colors, 'SystemOrange');
  }

  cursor: pointer;
}

// Variant switch

.CheckboxField--switch .CheckboxField__inner {
  width: 100%;
  flex-direction: row-reverse;
}

.CheckboxField--switch .CheckboxField__control {
  position: relative;
  margin-left: auto;
  width: 3.6rem;
  height: 2rem;
  border-radius: 3.6rem;
  border: 0.1rem solid map.get($colors, 'Primary500');
  background-color: map.get($colors, 'Neutral100');
  transition: background-color $timing-fast;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    background-color: map.get($colors, 'Primary500');
    transform: translate(-100%, -50%);
    transition: transform $timing-fast, background-color $timing-fast;
  }
}

.CheckboxField--switch .CheckboxField__label {
  color: map.get($colors, 'Neutral600');
  font-size: 1.4rem;
  font-weight: 600;
}

.CheckboxField--switch .CheckboxField__input:checked + .CheckboxField__control {
  background-color: map.get($colors, 'Primary500');
}

.CheckboxField--switch .CheckboxField__input:checked + .CheckboxField__control:before {
  transform: translate(0%, -50%);
  background-color: map.get($colors, 'Neutral100');
}

// Hide HTML Checkbox input
.CheckboxField__input {
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  border: 0;
  visibility: hidden;
}

.CheckboxField .FieldError {
  margin-left: 0;
}

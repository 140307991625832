.PollAddQuestion {
  margin-bottom: 2rem;
}

.PollAddQuestion .CardGallery {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.PollAddQuestion .CardAttachments .Card__link {
  padding: 1rem 1.4rem;
}

.PollAddQuestion__percentage {
  width: 100%;
  max-width: 6rem;
  margin-left: 1rem;

  .TextField__control {
    text-align: center;
    padding: 0.8rem 0;
  }
}

.PollAddQuestion__add-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 3rem;
  padding: 1.9rem 2.8rem;

  color: map.get($colors, 'Primary500');
  border: 0.1rem solid map.get($colors, 'Neutral400');
  border-radius: 0.8rem;

  cursor: pointer;

  &--loading {
    cursor: progress;
  }
}

.PollAddQuestion__remove {
  cursor: pointer;
}

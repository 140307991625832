.animated {
  transition: all $timing-normal;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

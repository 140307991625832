.FormMessage,
.FlashMessage {
  --weight: 600;
  --color: #{map.get($colors, 'Neutral700')};

  position: relative;
  padding: 1.6rem 2.4rem;
  border-radius: 0.4rem;
  color: var(--color);
  background-color: #{map.get($colors, 'Neutral100')};

  overflow: hidden;

  &--error {
    --color: #{map.get($colors, 'SystemRed')};
  }

  &--success {
    --color: #{map.get($colors, 'SystemGreen')};
  }

  &--warning {
    --color: #{map.get($colors, 'SystemOrange')};
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &:before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    background-color: currentColor;
    width: 0.4rem;
    height: 100%;
  }

  &__close {
    flex-shrink: 0;
    font-size: 1.8rem;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity $timing-fast;

    &:hover {
      opacity: 1;
    }
  }
}

.FormMessage--dark {
  background-color: #{map.get($colors, 'Neutral200')};
}

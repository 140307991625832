.Card {
  background-color: map.get($colors, 'Neutral100');
  border-radius: 1rem;
}

.Card--content {
  padding: $cardContent-padding-y $cardContent-padding-x;
}

.Card .Card__divider {
  border-bottom: 0.1rem solid map.get($colors, 'Neutral300');
}

// Card with fullrow switcher
.Card .Card__Switcher .CheckboxField__inner {
  padding: 1.8rem 3.6rem;
}

// Card with fullrow link
.Card .Card__link {
  display: flex;
  align-items: center;
  padding: 1.8rem 3.6rem;
  color: map.get($colors, 'Neutral600');
  text-decoration: none;

  a {
    display: flex;
    align-items: center;
  }

  &,
  .icon {
    transition: color $timing-fast;
  }

  &:hover,
  &:focus {
    color: map.get($colors, 'Neutral700');
  }

  .icon {
    font-size: 2.4rem;
  }
}

// Card with table

.Card--table {
  padding: 3.2rem;

  .Table {
    tr:first-child {
      th,
      td {
        padding-top: 0;
      }
    }

    tr:last-child {
      th,
      td {
        padding-bottom: 0;
      }
    }
  }
}

.CardUserList {
  display: flex;
  align-items: center;

  position: relative;
  padding: 1.6rem;
  color: inherit;
  transition: background-color $timing-fast;

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: map.get($colors, 'Neutral300');
  }
}

.CardUserList__typeIcon {
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 1.6rem;
}

.CardUserList__icon.CardUserList__icon {
  color: map.get($colors, 'Neutral600');
}

.CardUserList__link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: calc(100% + 1.6rem * 2);
  flex-shrink: 0;
  top: 0;
  bottom: 0;
  right: -1.6rem;
  padding: 1rem;
  margin-left: auto;
}

.CardUserList__tickets {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  margin-top: 0.8rem;
}

.CardUserList--bordered {
  border: 1px solid map.get($colors, 'Neutral300');
}

.CardUserList--icon {
  padding-right: 5rem;
}

.CardActions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.8rem;
  margin-left: 2rem;

  .icon.icon {
    font-size: 1.4rem;
    cursor: pointer;
  }

  .icon-edit.icon-edit {
    color: map.get($colors, 'Primary500');
  }

  .icon-trash.icon-trash.icon-trash {
    color: map.get($colors, 'SystemRed');
  }
}

.CardWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 3.2rem;

  .Card {
    width: 100%;

    @media (min-width: $hamburger-breakpoint) {
      width: calc(50% - 1.6rem);
    }
  }
}

.CardinputMin {
  max-width: 8rem;
  margin-right: 1rem;
}

.CardSubscription .CardMessage__content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

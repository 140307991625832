.PollMissingContacts__tag {
  margin-left: $base-indent * 2;
  padding: 0.4rem 0.8rem;
  border-radius: 0.6rem;
  border: 0.1rem solid map.get($colors, 'Neutral400');

  & + .PollMissingContacts__tag {
    margin-left: $base-indent;
  }
}

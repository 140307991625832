.Button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1.7rem 3.1rem;

  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 600;
  text-align: center;
  text-decoration: none;

  background: transparent;
  border: 0.1rem solid transparent;
  border-radius: 0.8rem;
  cursor: pointer;

  &,
  &:hover,
  &:focus {
    text-decoration: none;
  }

  transition: color $timing-fast, background-color $timing-fast, border-color $timing-fast;
}

.Button--small {
  padding: 1rem 1.4rem;
}

.Button--wide {
  min-width: 30rem;
}

.Button--rounded {
  border-radius: 10rem;
}

.Button--disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.Button__label {
  transition: opacity $timing-fast;
}

.Button__icon {
  color: inherit;

  &--before {
    margin-right: 1rem;
  }

  &--after {
    margin-left: 1rem;
  }
}

.Button--loading {
  cursor: wait;
}

.Button--loading .Button__label {
  opacity: 0;
}

.Button__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Button--primary {
  --spinner-background: currentColor;
  --spinner-loader: #{map.get($colors, 'Primary700')};

  color: map.get($colors, 'Neutral100');
  background-color: map.get($colors, 'Primary500');
  border-color: map.get($colors, 'Primary500');

  &:hover,
  &:focus {
    background-color: map.get($colors, 'Primary600');
    border-color: map.get($colors, 'Primary600');
  }
}

.Button--secondary {
  color: map.get($colors, 'Primary500');
  border-color: map.get($colors, 'Primary500');

  &:hover,
  &:focus {
    background-color: map.get($colors, 'Neutral100');
    border-color: map.get($colors, 'Neutral100');
  }
}

.Button--secondary-outline {
  color: map.get($colors, 'Neutral100');
  border-color: map.get($colors, 'Neutral100');

  &:hover,
  &:focus {
    color: map.get($colors, 'Neutral700');
    background-color: map.get($colors, 'Neutral100');
    border-color: map.get($colors, 'Neutral100');
  }
}

.Button--primary-light {
  color: map.get($colors, 'Primary500');
  background-color: map.get($colors, 'Primary100');

  &:hover,
  &:focus {
    color: map.get($colors, 'Primary500');
    background-color: map.get($colors, 'Primary200');
  }
}

.Button--text {
  padding: 0;
  color: map.get($colors, 'Primary500');

  &:hover,
  &:focus {
    color: map.get($colors, 'Primary600');
  }
}

// Question buttons variant
.Button--question-yes {
  &.is-active,
  &:hover,
  &:focus {
    color: map.get($colors, 'Neutral100');
    background-color: map.get($colors, 'SystemGreen');
  }
}

.Button--question-no {
  &.is-active,
  &:hover,
  &:focus {
    color: map.get($colors, 'Neutral100');
    background-color: map.get($colors, 'SystemRed');
  }
}

.Button--question-undecided {
  &.is-active,
  &:hover,
  &:focus {
    color: map.get($colors, 'Neutral100');
    background-color: map.get($colors, 'SystemOrange');
  }
}

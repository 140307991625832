.CardFeed {
  padding: 1.8rem;

  .Card__divider {
    margin: 1.8rem -1.8rem;
  }

  .CardFeed__type {
    padding: 0.2rem 0.3rem;
    border: 0.1rem solid map.get($colors, 'Neutral300');
    border-radius: 0.4rem;
    margin-right: 1.6rem;
  }

  &__header {
    display: flex;
    align-items: baseline;
  }

  &__button {
    margin-left: auto;
    color: map.get($colors, 'Neutral700');
  }

  &__message {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

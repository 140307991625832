.FileField {
  display: flex;
  flex-direction: column;

  position: relative;
  padding: $base-indent;
  border: 0.1rem dashed map.get($colors, 'Neutral300');
  border-radius: 0.8rem;

  @media (min-width: $screen-lg) {
    flex-direction: row;
    align-items: center;
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
  }

  &__button {
    pointer-events: none;
    margin-right: $base-indent;
    align-self: flex-start;
  }
}

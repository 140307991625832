.profileEditAvatar {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: $cardContent-padding-y;
  margin-bottom: $cardContent-padding-x;

  &__change {
    cursor: pointer;

    &:hover,
    &:focus {
      color: map.get($colors, 'Primary600');
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -$cardContent-padding-x;
    right: -$cardContent-padding-x;
    border-bottom: 0.1rem solid map.get($colors, 'Neutral300');
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 0;
    height: 0;
  }
}

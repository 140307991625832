.BuildingInfoCard {
  display: flex;
  flex-direction: column;
  border-bottom: 0.1rem solid map.get($colors, 'Neutral300');
  margin-bottom: 2rem;

  @media (min-width: $screen-lg) {
    flex-direction: row;
    gap: 2rem;
  }

  .BuildingInfo__addresses {
    width: 100%;
  }

  .BuildingInfo__addresses,
  .BuildingInfo__table {
    @media (min-width: $screen-lg) {
      max-width: calc(50% - 1rem);
    }
  }
}

.UserList--top {
  @media (min-width: $hamburger-breakpoint) {
    margin-top: -$content-padding-y;
  }
}

.UserList {
  &__header {
    position: sticky;
    top: var(--header-height);
    background-color: map.get($colors, 'Neutral200');
    z-index: 9;
  }

  &__row {
    display: flex;
    align-items: center;
    padding-top: 2.4rem;
    padding-bottom: 2rem;
    border-bottom: 0.1rem solid map.get($colors, 'Neutral300');

    &:last-child {
      border-bottom: 0;
    }
  }

  &__header .UserList__row {
    padding-top: 2rem;
    padding-bottom: 1.2rem;
    border-bottom: 0.1rem solid map.get($colors, 'Neutral300');
  }

  &__cell {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 2rem;

    width: 100%;
    margin-right: 2rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__cell--small {
    max-width: 26rem;

    .Card {
      width: 100%;
    }
  }
}

.UserList__count {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
}

.CardUserList--unit {
  position: sticky;
  top: calc(var(--header-height) + var(--unit-list-height) + 0.8rem);
}

.List {
  --gap: 2rem;

  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);

  .Card {
    width: 100%;

    @media (min-width: $screen-lg) {
      width: calc(50% - (var(--gap) / 2));
    }
  }
}

.Tooltip {
  position: absolute;
  top: 0;
  left: 0;

  padding: 0.8rem 1.2rem;
  border-radius: 0.3rem;

  z-index: 100;
  pointer-events: none;

  font-size: 1.2rem;
  font-weight: 500;
  color: map.get($colors, 'Neutral750');
  background-color: map.get($colors, 'Neutral300');
}

.Tooltip__Content {
  max-width: 36rem;
}

.Tooltip__Arrow {
  position: absolute;
  transform: rotate(45deg);
  width: 1rem;
  height: 1rem;
  z-index: -1;

  background-color: map.get($colors, 'Neutral300');
}

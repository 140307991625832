.TextField {
  display: flex;
  flex-direction: column;
}

.TextField__control {
  padding: 1.4rem 1.6rem;
  width: 100%;

  color: map.get($colors, 'Neutral750');
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 500;

  background-color: map.get($colors, 'Neutral100');
  border: 0.1rem solid map.get($colors, 'Neutral300');
  border-radius: 0.8rem;

  outline: none;

  transition: border-color $timing-fast, color $timing-fast;

  &::placeholder {
    color: map.get($colors, 'Neutral600');
  }

  &:hover,
  &:focus {
    border-color: map.get($colors, 'Neutral500');

    &::placeholder {
      color: map.get($colors, 'Neutral700');
    }
  }

  &:disabled {
    cursor: not-allowed;

    &:hover,
    &:focus {
      border: 0.1rem solid map.get($colors, 'Neutral300');
    }
  }
}

textarea.TextField__control {
  min-height: 18rem;

  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

.TextField--password .CheckboxField {
  margin-left: auto;
}

.TextField--password .CheckboxField__label {
  margin-bottom: 0;
  text-transform: none;
}

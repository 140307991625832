.CardQuestionResults {
  display: flex;
  align-items: center;
  padding-left: 1.6rem;

  &__info {
    flex: 1;
    border-left: 0.1rem solid map.get($colors, 'Neutral300');
    margin-left: 1.6rem;
  }

  &__title,
  &__results {
    padding: 1.6rem !important;
  }

  &__title {
    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    border-bottom: 0.1rem solid map.get($colors, 'Neutral300');

    @media (min-width: $screen-md) {
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }
  }

  &__results {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem 3.2rem;
  }
}

.CardQuestionResults__decision {
  flex-shrink: 0;
}

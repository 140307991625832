.Avatar {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  width: 3.2rem;
  height: 3.2rem;

  border-radius: 50%;
  overflow: hidden;

  background-color: map.get($colors, 'Neutral200');

  text-align: center;
}

.Avatar__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Avatar--large {
  width: 4rem;
  height: 4rem;
}

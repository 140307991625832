.Question + .Question {
  margin-top: 2.4rem;
}

.CardQuestion {
  padding: 1.6rem 2.4rem;

  &Label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.2rem;
    padding: 0 1.6rem;

    &__link {
      font-size: 1.2rem;
      line-height: 1.6rem;
      font-weight: 600;
      color: map.get($colors, 'Primary500');
      display: inline-flex;
      align-items: center;

      transition: color $timing-fast;

      .icon {
        font-size: 1.6rem;
        transition: color $timing-fast;
      }

      &:hover,
      &:focus {
        text-decoration: none;
        color: map.get($colors, 'Primary600');
      }
    }
  }

  &Answers {
    display: flex;
    gap: 1.6rem;
  }

  &Answer {
    width: 100%;

    & > div {
      width: 100%;
    }

    .Button {
      width: 100%;
      height: 100%;
    }
  }
}

.Modal {
  .CardQuestionLabel,
  .CardQuestion {
    padding: 0;
  }
}

@mixin utility-classes($annotation: '') {
  @each $clsName, $properties in $utility-classes-maps {
    @if $utility-classes-iterations != -1 {
      .#{$clsName}-auto#{$annotation} {
        @include properties($properties, 'auto', 1, !important);
      }

      @for $i from 0 through $utility-classes-iterations {
        .#{$clsName}-#{$i}#{$annotation} {
          @include indent($properties, $i, $base-indent, !important);
        }
      }
    }
  }
}

.CardGallery {
  display: grid;
  grid-template-columns: 1fr;
  gap: $cardContent-padding-x;

  padding: $cardContent-padding-x;

  @media (min-width: $screen-md) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

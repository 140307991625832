.Page {
  display: flex;
  flex-shrink: 0;
}

.Main {
  display: flex;
  flex-direction: column;

  width: 100%;
}

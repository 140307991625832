.Spinner {
  position: relative;

  width: 4.4rem;
  height: 4.4rem;
  border-radius: 100%;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 0.2rem solid transparent;
    border-top-color: var(--spinner-loader, #{map.get($colors, 'Primary500')});
  }

  &:before {
    z-index: 10;
    animation: spinnerAnimation 1s infinite;
  }

  &:after {
    border: 0.2rem solid var(--spinner-background, #{map.get($colors, 'Neutral300')});
  }
}

.Spinner--small {
  width: 2.4rem;
  height: 2.4rem;
}

.Spinner--large {
  width: 5.6rem;
  height: 5.6rem;

  &:before,
  &:after {
    border-width: 0.3rem;
  }
}

.Spinner--overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spinnerAnimation {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.SpinnerPageWrapper {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: map.get($colors, 'Neutral200');
}

.FullPageSpinner {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  max-height: 100vh;
}

.ContactDetail {
  display: inline-flex;
  align-items: center;
  color: map.get($colors, 'Neutral700');

  &:hover,
  &:focus {
    text-decoration: none;
    color: map.get($colors, 'Blue600');
  }
}

.ContactDetail__icon {
  width: 2rem;
  height: 2rem;
}

.ContactDetail__label {
  color: inherit;
  margin-left: 1rem;

  @media (max-width: $screen-lg-max) {
    display: none;
  }
}

// List of ContactDetails...
.ContactDetail + .ContactDetail {
  margin-left: 2rem;

  @media (min-width: $screen-lg) {
    margin-left: 3rem;
  }
}

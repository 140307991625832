.AccordionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem 3rem;
  border-radius: 0.8rem;
  border: 0.1rem solid transparent;
  transition: border $timing-normal;

  user-select: none;
  cursor: pointer;
}

.Accordion__toggler {
  margin-left: 2rem;
  transition: transform $timing-fast;
}

.Accordion--closed .Accordion__toggler {
  transform: rotate(-180deg);
}

.Accordion--closed .AccordionHeader {
  border-color: map.get($colors, 'Neutral400');
}

.AccordionContentWrapper {
  height: 0;
  overflow: hidden;
  transition: height $timing-normal;
}

.AccordionContent {
  margin-top: 0.8rem;
  padding: 3.2rem;
  border: 0.1rem solid map.get($colors, 'Neutral400');
  border-radius: 1.6rem;
}

.SelectField__control {
  border: 0.1rem solid map.get($colors, 'Neutral300') !important;
  border-radius: 0.8rem !important;
  background-color: map.get($colors, 'Neutral100') !important;
  box-shadow: none !important;
  transition: border-color $timing-fast, color $timing-fast !important;
  cursor: pointer !important;
}

.SelectField__control:hover,
.SelectField__control--is-focused {
  border-color: map.get($colors, 'Neutral500') !important;
}

.SelectField__value-container {
  padding: 1.4rem 1.6rem !important;
  margin: 0 !important;

  font-size: 1.4rem !important;
  line-height: 2rem !important;
  font-weight: 500 !important;
}

.SelectField__input-container,
.SelectField__single-value {
  padding: 0 !important;
  margin: 0 !important;
}

.SelectField__placeholder {
  margin: 0 !important;
  color: map.get($colors, 'Neutral600') !important;
}

.SelectField__control:hover .SelectField__placeholder {
  color: map.get($colors, 'Neutral700') !important;
}

.SelectField__single-value {
  color: map.get($colors, 'Neutral750') !important;
}

.SelectField__menu-list {
  padding: 1.4rem 1.8rem !important;
}

.SelectField__menu {
  box-shadow: none !important;
  border: 0.1rem solid map.get($colors, 'Neutral200') !important;
  border-radius: 1.6rem !important;
}

.SelectField__option {
  padding: 1.2rem 1rem !important;
  border-radius: 0.8rem !important;
  color: map.get($colors, 'Neutral750') !important;
  margin-bottom: 1rem !important;
  font-size: 1.4rem !important;
  line-height: 2.4rem !important;
  cursor: pointer !important;

  &:last-child {
    margin-bottom: 0 !important;
  }
}

.SelectField__option--is-focused {
  color: map.get($colors, 'Neutral800') !important;
  background-color: map.get($colors, 'Neutral200') !important;
}

.SelectField__option--is-selected {
  color: map.get($colors, 'Primary500') !important;
  background-color: map.get($colors, 'Primary100') !important;
}

.Content {
  flex: 1;
  flex-shrink: 0;

  padding: $base-indent $base-indent * 2;

  width: 100%;
  max-width: 12rem + 2rem + 88rem; // (12rem + 2rem) = horizontal padding

  @media (min-width: $screen-lg) {
    padding: $content-padding-y 2rem;
    padding-left: 12rem;
  }
}

.Content__divider {
  width: 100%;
  height: 0.1rem;
  margin: 4rem 0;
  background-color: map.get($colors, 'Neutral300');
}

.Content__footer {
  position: sticky;
  bottom: 0;

  margin-top: auto;
  margin-bottom: -$content-padding-y;
  padding: 2rem 0;
  padding-top: 4rem;
  background-color: map.get($colors, 'Neutral200');

  &:before {
    content: '';
    position: absolute;
    top: 2rem;
    width: 100%;
    border-top: 0.1rem solid map.get($colors, 'Neutral300');
  }
}

.Content--empty__image {
  max-width: 100%;
}

// Temporary solution for 404 page error
.Content--fullscreen {
  padding: $base-indent $base-indent * 2;
  width: 100%;
  max-width: none;
}

#root > .Content--fullscreen {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.TextField__label,
.SelectField__label {
  display: flex;
  align-items: center;

  margin-left: 1.4rem;
  margin-bottom: 1.2rem;

  color: map.get($colors, 'Neutral700');
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 500;

  text-transform: uppercase;

  a {
    color: map.get($colors, 'SystemOrange');
  }

  &-required {
    line-height: inherit;
    margin-left: 0.2rem;
  }
}

.Label__tooltip {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;

  border: 1px solid map.get($colors, 'Neutral700');

  line-height: 1;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  margin-left: $base-indent;
}

.RadioFieldLabel .FieldError {
  margin-left: 0;
  margin-top: -1rem;
}

.RadioFieldLabel label {
  margin-left: 0;
}

html {
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-size: 16px;
  text-rendering: optimizeLegibility;
  font-family: 'Inter', sans-serif;

  background-color: map.get($colors, 'Neutral200');
}

* {
  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

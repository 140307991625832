.PollChart {
  display: inline-flex;

  svg {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    transform: rotate(-90deg);

    @media (min-width: $screen-md) {
      width: 7.2rem;
      height: 7.2rem;
    }
  }

  svg circle {
    fill: none;
    r: 16px;
    cx: 16px;
    cy: 16px;
    stroke-width: 6;
  }

  &__background {
    stroke: map.get($colors, 'Neutral300');
  }

  &__value {
    &--yes {
      stroke: map.get($colors, 'SystemGreen');
    }

    &--no {
      stroke: map.get($colors, 'SystemRed');
    }

    &--undecided {
      stroke: map.get($colors, 'SystemOrange');
    }
  }
}

$_Sidebar-section-gap: 3.2rem;

.Sidebar {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;

  height: 100vh;
  max-height: 100vh;

  width: 100%;
  max-width: 24.8rem;

  background-color: map.get($colors, 'Blue800');

  z-index: 99;
  transform: translateX(-100%);

  transition: transform $timing-fast;

  .sidebar--is-opened & {
    transform: translateX(0);
  }

  @media (min-width: $screen-lg) {
    position: sticky;
    transform: none;
  }
}

.Sidebar__inner {
  display: flex;
  flex-direction: column;
  padding: $_Sidebar-section-gap $base-grid-gap;
  overflow: auto;
}

.SidebarHeader,
.SidebarUser,
.SidebarUser__wrapper {
  position: relative;
  display: flex;
  align-items: center;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -$base-grid-gap;
    right: -$base-grid-gap;

    border-bottom: 0.1rem solid map.get($colors, 'Blue700');
  }
}

// Sidebar Header
.SidebarHeader {
  margin-bottom: 4rem;
  padding-bottom: $_Sidebar-section-gap;
}

.SidebarHeader__logo {
  margin-right: auto;
}

.SidebarHeader__notifications {
  color: map.get($colors, 'Blue500');
  font-size: 2rem;
}

// Sidebar Unit
.SidebarUnit {
  margin-bottom: 5rem;
}

.SidebarUnit__address {
  margin-right: 2rem;
  user-select: none;
}

.SidebarUnit__switcher {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: auto;
  flex-shrink: 0;

  width: 3.6rem;
  height: 3.6rem;
  border: 0.1rem solid map.get($colors, 'Blue600');
  border-radius: 50%;

  transition: transform $timing-fast, background-color $timing-fast;

  cursor: pointer;

  &--is-opened {
    transform: rotate(180deg);
  }
}

.SidebarUnit__buildings {
  margin-top: 1.6rem;
  padding: 1.2rem;
  margin-bottom: -2rem;
}

.SidebarUnit__building {
  display: flex;
  align-items: center;

  cursor: pointer;
  padding: 1.3rem 1.5rem;
  margin-bottom: 0.5rem;
  transition: background-color $timing-fast;
  border-radius: 0.8rem;

  &:hover {
    background-color: map.get($colors, 'Primary100');
  }

  &--active,
  &--active:hover {
    background-color: map.get($colors, 'Primary200');
  }

  &:last-child {
    margin-bottom: 0;
  }
}

// Sidebar Nav

.SidebarNav {
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
}

.SidebarNav__item {
  position: relative;

  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  padding-left: 4rem;
  margin-bottom: 3.5rem;
  color: map.get($colors, 'Neutral500');
  transition: color $timing-normal;

  &,
  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.SidebarNav__item-icon {
  position: absolute;
  top: 50%;
  left: 0;
  width: 2rem;
  height: 2rem;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  transition: opacity $timing-fast, visibility $timing-fast;
}

.SidebarNav__item:not(.SidebarNav__item--active) .SidebarNav__item-icon--inactive {
  opacity: 1;
  visibility: visible;
}

.SidebarNav__item--active {
  color: map.get($colors, 'Primary400');

  .SidebarNav__item-icon--active {
    opacity: 1;
    visibility: visible;
  }
}

// Sidebar subscription
.SidebarSubscription {
  $_gap: math.div($base-grid-gap, 2) * -1;

  margin-top: auto;
  margin: auto $_gap 0 $_gap;
  padding: 0.8rem 1rem;
  border-radius: 0.8rem;
  background-color: map.get($colors, 'Blue700');
  text-align: center;
}

// Sidebar User
.SidebarUser {
  margin-top: auto;
  padding-top: $_Sidebar-section-gap;

  &:after {
    top: 0;
    bottom: auto;
  }

  &--with-warning {
    margin-top: 1.6rem;
  }
}

.SidebarUser__wrapper {
  width: 100%;
  text-decoration: none;

  &:after {
    content: none;
  }

  &:hover {
    text-decoration: none;
  }
}

.SidebarUser__info {
  min-width: 0; // Hack to overflow ellipsis

  & > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.SidebarNotification {
  margin: -$base-grid-gap;
  margin-top: auto;
  margin-bottom: 0;
  padding: 1.6rem;

  text-align: center;
}

.SidebarNotification--warning {
  background-color: map.get($colors, 'SystemOrange');
}

.SidebarNotification--error {
  background-color: map.get($colors, 'SystemRed');
}

.SidebarNotification__button {
  width: 100%;
}

.SidebarNotification + .SidebarUser {
  margin-top: 0;
}

@use 'sass:map';
@use 'sass:math';

// Vendor
@import 'flatpickr/dist/flatpickr.css';
@import 'normalize.css/normalize';

// Utils
@import './utils/variables.scss';
@import './utils/animations.scss';

// Mixins
@import './mixins/aspect-ratio.scss';
@import './mixins/properties.scss';
@import './mixins/indent.scss';
@import './mixins/utility-classes.scss';

// Base
@import './base/base.scss';
@import './base/col.scss';
@import './base/icon.scss';
@import './base/layout.scss';
@import './base/row.scss';
@import './base/typography.scss';

// Patterns
@import './patterns/Building.scss';
@import './patterns/onboarding.scss';
@import './patterns/profileEdit.scss';
@import './patterns/PollAddQuestion.scss';
@import './patterns/PollMissingContacts.scss';

// Components
@import './components/Accordion.scss';
@import './components/Avatar.scss';
@import './components/Button.scss';
@import './components/Content.scss';
@import './components/Card/Card.scss';
@import './components/Card/CardAttachments.scss';
@import './components/Card/CardContact.scss';
@import './components/Card/CardFeed.scss';
@import './components/Card/CardGallery.scss';
@import './components/Card/CardMessage.scss';
@import './components/Card/CardPoll.scss';
@import './components/Card/CardQuestion.scss';
@import './components/Card/CardQuestionResults.scss';
@import './components/ContactDetail.scss';
@import './components/Editor.scss';
@import './components/FieldError.scss';
@import './components/FlashMessage.scss';
@import './components/FormMessage.scss';
@import './components/Hamburger.scss';
@import './components/Header.scss';
@import './components/Image.scss';
@import './components/PollChart.scss';
@import './components/List.scss';
@import './components/Modal.scss';
@import './components/Sidebar.scss';
@import './components/Label.scss';
@import './components/CheckboxField.scss';
@import './components/RadioField.scss';
@import './components/FileField.scss';
@import './components/TextField.scss';
@import './components/SelectField.scss';
@import './components/Spinner.scss';
@import './components/Table.scss';
@import './components/UserList.scss';
@import './components/Tooltip.scss';
@import './components/Divider.scss';

// Utility classes
@import './utils/classes.scss';

.Page__footer {
  position: sticky;
  bottom: 0;
}

.out {
  opacity: 0;
  transition: opacity 300ms;
}

.in {
  opacity: 1;

  transition: opacity 300ms;
}

.idle {
  opacity: 1;

  transition: opacity 300ms;
}

.RadioField__inner {
  display: flex;
  align-items: center;
  cursor: pointer;
}

// Variant radio
.RadioField .RadioField__control {
  position: relative;

  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  border: 0.1rem solid map.get($colors, 'Neutral700');
  flex-shrink: 0;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.9rem;
    height: 0.9rem;
    border-radius: 50%;
    background-color: map.get($colors, 'Blue600');
    opacity: 0;
  }
}

.RadioField .RadioField__input:checked + .RadioField__control {
  border-color: map.get($colors, 'Blue600');

  &:after {
    opacity: 1;
  }
}

.RadioField .RadioField__label {
  margin-left: 1.4rem;

  color: map.get($colors, 'Neutral700');
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 500;

  cursor: pointer;
}

// Hide HTML Radio input
.RadioField__input {
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  border: 0;
  visibility: hidden;
}

.RadioField .FieldError {
  margin-left: 0;
}

.RadioField__description {
  margin-top: 0.3rem;
  margin-left: 3rem;

  cursor: pointer;
}

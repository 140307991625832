@use 'sass:map';
@use 'sass:math';

$hamburger-width: 2.8rem !default;
$hamburger-layer-height: 0.2rem !default;
$hamburger-layer-spacing: 0.6rem !default;

$hamburger-layer-color: map.get($colors, 'Blue500');

$hamburger-layer-color-hover: map.get($colors, 'Blue800');

$hamburger-light-layer-color: map.get($colors, 'Neutral400');
$hamburger-light-layer-color-hover: map.get($colors, 'Neutral100');

$hamburger-layer-border-radius: 0.2rem !default;

.Hamburger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
  width: $hamburger-width;

  @media (min-width: $hamburger-breakpoint) {
    display: none;
  }
}

.Hamburger-box {
  position: relative;
  display: inline-block;

  width: 100%;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
}

.Hamburger-inner {
  display: block;
  top: 50%;
  margin-top: math.div($hamburger-layer-height, -2);

  transition-duration: 0.075s, $timing-fast;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19), ease;

  &,
  &::before,
  &::after {
    width: 100%;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform, background-color;
    transition-duration: $timing-fast, $timing-fast;
    transition-timing-function: ease, ease;
  }

  &::before,
  &::after {
    content: '';
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    transition: top 0.075s 0.12s ease, opacity 0.075s ease, background-color $timing-fast ease;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19),
      background-color $timing-fast ease;
  }
}

.Hamburger.Hamburger--opened .Hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s, 0;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1), ease;

  &::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease, background-color $timing-fast;
  }

  &::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1),
      background-color $timing-fast;
  }
}

.Hamburger:hover .Hamburger-inner {
  &,
  &:before,
  &:after {
    background-color: $hamburger-layer-color-hover;
  }
}

.Hamburger--light {
  .Hamburger-inner {
    &,
    &:before,
    &:after {
      background-color: $hamburger-light-layer-color;
    }
  }

  &:hover .Hamburger-inner {
    &,
    &:before,
    &:after {
      background-color: $hamburger-light-layer-color-hover;
    }
  }
}

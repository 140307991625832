.FlashMessage__container {
  position: fixed;
  top: 2rem;
  left: 50%;

  display: flex;
  flex-direction: column;
  gap: 2rem;

  width: 100%;
  max-width: 50rem;
  transform: translateX(-50%);
  z-index: 190;
}

.FlashMessage--content {
  position: relative;
}

.FlashMessage__link {
  color: inherit;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

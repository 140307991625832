@use 'sass:math';

.col {
  display: flex;
  flex-direction: column;
  padding: 0 math.div($base-grid-gap, 2);

  // Generate cols
  @for $i from 1 through 12 {
    &-#{$i} {
      width: calc((100% / 12) * #{$i});
      max-width: calc((100% / 12) * #{$i});
      flex-basis: calc((100% / 12) * #{$i});
    }
  }

  @media (max-width: $screen-md) {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
}

// Generate indetations

@include utility-classes();

@media (min-width: $screen-sm) {
  @include utility-classes('-sm');
}

@media (min-width: $screen-md) {
  @include utility-classes('-md');
}

@media (min-width: $screen-lg) {
  @include utility-classes('-lg');
}

@each $key, $value in $colors {
  .#{$key} {
    --color: #{$value};
  }
}

.Inherit {
  color: inherit;
}

@each $value in 400, 500, 600, 700 {
  .Weight#{$value} {
    --weight: #{$value};
  }
}

@each $value in left, center, right {
  .text--#{$value} {
    text-align: #{$value};
  }
}

.text-uppercase {
  text-transform: uppercase;
}

// Flex classes

.Flex {
  display: flex;
  flex-direction: row;
}

.FlexColumn {
  display: flex;
  flex-direction: column;
}

.FlexWrap {
  flex-wrap: wrap;
}

.FlexAlign--start {
  align-items: flex-start;
}

.FlexAlign--center {
  align-items: center;
}

.FlexAlign--end {
  align-items: flex-end;
}

.FlexJustify--start {
  justify-content: flex-start;
}

.FlexJustify--center {
  justify-content: center;
}

.FlexJustify--end {
  justify-content: flex-end;
}

.FlexJustify--space-between {
  justify-content: space-between;
}

.FlexGap--1 {
  gap: $base-indent;
}

.icon-pencill,
.icon-pencilr,
.icon-tlight,
.icon-tregular {
  font-size: 1.6rem !important;
}

.CardPoll {
  display: flex;
  flex-direction: column;
  padding: 1.8rem;

  .Card__divider {
    margin: 1.8rem -1.8rem;
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__icon {
    font-size: 2.4rem;
    margin-right: 1.2rem;

    &.icon-bullHorn {
      color: map.get($colors, 'AccentOrange');
    }
  }

  &__quorum {
    display: inline-flex;
    align-items: center;
  }

  &__quorum-divider {
    content: '';
    display: inline-block;
    width: 0.4rem;
    height: 0.4rem;
    margin: 0 0.4rem;
    background-color: map.get($colors, 'Neutral400');
    border-radius: 50%;
    flex-shrink: 0;
  }

  &Details {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: 1;
  }

  &--completed {
    background-color: map.get($colors, 'Neutral300');

    .Card__divider {
      border-color: map.get($colors, 'Neutral400');
    }
  }
}

.Header {
  display: flex;
  flex-direction: column;
  align-items: center;

  position: sticky;
  top: 0;
  padding: $base-indent $base-indent * 2;
  min-height: 6.8rem;
  border-bottom: 0.1rem solid map.get($colors, 'Neutral400');
  background-color: map.get($colors, 'Neutral200');
  z-index: 9;

  @media (min-width: $screen-lg) {
    padding: 2.8rem 0;
    padding-right: 4rem;
  }
}

.Header--sm {
  padding: $base-indent $base-indent * 2;
  min-height: 0;

  @media (min-width: $hamburger-breakpoint) {
    display: none;
  }
}

.Header--has-tabs {
  padding-bottom: 0;
}

.HeaderTitle {
  display: flex;
  align-items: center;
  width: 100%;

  &--lg {
    @media (max-width: $hamburger-breakpoint-max) {
      display: none;
    }
  }
}

.HeaderSearch {
  display: none;
  margin-left: 2.4rem;

  @media (min-width: $screen-lg) {
    display: block;
  }

  .TextField__control {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
    padding-left: 3.5rem;
    border-radius: 10rem;
    background-image: url('../../images/icons/search.svg');
    background-repeat: no-repeat;
    background-position: left 1rem center;
  }
}

.HeaderTextField {
  width: 100%;
  padding: 0;

  border: 0;
  border-radius: 0;
  background-color: transparent;
  word-break: break-all;
  outline: none;

  &[contenteditable='true']:empty:before {
    content: attr(placeholder);
    pointer-events: none;
    display: block; /* For Firefox */
    color: map.get($colors, 'Neutral600');
  }
}

.HeaderRow {
  position: relative;

  display: flex;
  align-items: center;
  width: 100%;

  @media (min-width: $screen-lg) {
    padding-left: 12rem;
  }
}

.HeaderRow--tabs {
  margin-top: 2rem;
}

.HeaderBacklink {
  margin-right: $base-indent;

  @media (min-width: $screen-lg) {
    position: absolute;
    top: 50%;
    left: 6rem;
    margin-right: 0;
    transform: translateY(-50%);
  }

  svg {
    display: flex;
    align-items: center;
    font-size: 1.5em;
    cursor: pointer;
  }

  &--lg {
    @media (max-width: $hamburger-breakpoint-max) {
      display: none;
    }
  }
}

.HeaderActions {
  display: flex;
  flex-shrink: 0;
  margin-left: auto;
  padding-left: 3rem;
  gap: 1rem;
}

.HeaderTab {
  position: relative;
  padding-bottom: 1rem;
  color: map.get($colors, 'Neutral600');
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.4rem;
  margin-right: 4rem;
  transition: color $timing-fast;

  &,
  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -0.1rem;
    left: 0;
    width: 100%;
    height: 0.2rem;
    background-color: transparent;
    transition: background-color $timing-fast;
  }

  &:last-child {
    margin-right: 0;
  }
}

.HeaderTab--active {
  color: map.get($colors, 'Neutral750');

  &:after {
    background-color: map.get($colors, 'Neutral700');
  }
}

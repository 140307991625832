.text-h1,
.text-h2,
.text-h3,
.text-body-large,
.text-body-medium,
.text-body-small,
.text-body-xs {
  padding: 0;
  margin: 0;
}

a,
.link {
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

a.no-underline {
  &,
  &:hover {
    text-decoration: none;
  }
}

.text-h1 {
  font-size: 2.6rem;
  line-height: 4rem;
  font-weight: var(--weight, 600);
  color: var(--color, map.get($colors, 'Blue800'));
}

.text-h2,
.HeaderTextField {
  font-size: 2rem;
  line-height: 3.2rem;
  font-weight: var(--weight, 600);
  color: var(--color, map.get($colors, 'Blue800'));
}

.HeaderTextField {
  @media (min-width: $hamburger-breakpoint) {
    font-size: 2.6rem;
    line-height: 4rem;
  }
}

.text-h3 {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: var(--weight, 600);
  color: var(--color, map.get($colors, 'Blue800'));
}

.text-body-large {
  font-size: 1.5rem;
  line-height: 2.4rem;
  font-weight: var(--weight, 500);
  color: var(--color, map.get($colors, 'Blue800'));
}

.text-body-medium {
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: var(--weight, 500);
  color: var(--color, map.get($colors, 'Blue800'));
}

.text-body-small {
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: var(--weight, 600);
  color: var(--color, map.get($colors, 'Blue800'));
}

.text-body-xs {
  font-size: 1.1rem;
  line-height: 1.6rem;
  font-weight: var(--weight, 600);
  color: var(--color, map.get($colors, 'Blue800'));
}

.text {
  font-size: inherit;
  line-height: inherit;
  font-weight: var(--weight, inherit);
  color: var(--color, inherit);
}

.not-clickable {
  pointer-events: none;
}

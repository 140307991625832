.CardMessage {
  padding: 1.6rem;

  .Card__divider {
    margin: 1.6rem -1.6rem;
  }

  &__title {
    margin-bottom: 1.6rem;
  }
}

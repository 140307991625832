.CardContact {
  position: relative;
  display: flex;
  padding: 2rem;
  margin-bottom: 1.6rem;

  .Button {
    margin-left: 1.6rem;
  }

  &Info {
    display: flex;
    align-items: center;
  }

  &Avatar {
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 4rem;

    &:before {
      content: '';
      position: absolute;
      top: -2rem;
      bottom: -2rem;
      right: 2rem;
      width: 0.1rem;
      background-color: map.get($colors, 'Neutral300');
    }
  }

  &Actions {
    display: flex;
    align-items: center;
  }

  &Admin {
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;
    opacity: 0;

    display: flex;
    gap: 1rem;

    transition: opacity $timing-fast;

    &__update,
    &__delete {
      cursor: pointer;
    }

    &__update {
      color: map.get($colors, 'Neutral700');
    }

    &__delete {
      color: map.get($colors, 'SystemRed');
    }
  }

  &:hover .CardContactAdmin {
    opacity: 1;
  }

  &Content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    width: 100%;
  }

  &Details {
    margin-right: auto;
  }
}
